import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { Container } from '@material-ui/core';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import Ribbon from '../../components/Ribbon';
import Maze from '../../components/Maze';
import { loodgieterswerkData } from '../../lib/overviewData';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "loodgieter/loodgieter-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "loodgieter/loodgieter-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages1: file(relativePath: { eq: "loodgieter/loodgieter.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages2: file(relativePath: { eq: "loodgieter/loodgieter-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages3: file(relativePath: { eq: "loodgieter/boiler-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const LoodggieterswerkOverview = ({ data, path, location }) => {
  const seo = {
    title: `E S I – Loodgieterswer`,
    description: `E S I - alle loodgieters diensten die E S I aanbiedt om u te helpen.`,
    pathname: path,
    location,
  };

  const GridImages = [data.gridImages1, data.gridImages2, data.gridImages3];

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `E S I – Loodgieterswerk`,
    pitchLine: `E S I – biedt diverse loodgieters diensten aan.`,
  };

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        location={location}
      />
      <Ribbon
        fluid={ribbon.image}
        heading={ribbon.heading}
        pitchLine={ribbon.pitchLine}
      />
      <Container fixed className='mt-10x mb-10x'>
        <Maze data={loodgieterswerkData} path={path} gridImages={GridImages} />
      </Container>
    </Layout>
  );
};

LoodggieterswerkOverview.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default LoodggieterswerkOverview;
